import store from '@/store/index.js';
import axios from '@/axiosConfig';

// import router from '@/router';
// import axios from 'axios';

export const ProfileUserService = {
    getData() {
        //const response = axios.get(`${store.getters['auth/ip']}/api/buyer`,
//.. )
        return  {
            id: 2701,
            type: 'COMPANY',
            name: 'Иван Сичкин',
            phone: '+79104647007',
            email: 'test@test.com',
            delivery: [
                {
                    id: 1,
                    title: 'Home',
                    name: 'Иван Сичкин',
                    address: '85000, Bar, Bar, Bulevar Tita 38A',
                    phone: '+79104647007',
                    default: true
                },
                {
                    id: 2,
                    title: 'Work',
                    name: 'Иван Сичкин',
                    address: 'Podgorica, Mira 75',
                    phone: '+79104647007',
                    default: false
                },
            ]
       };
    },


    async postData(data){
        try {
            var response = null;
            const accessToken = localStorage.getItem('accessToken');
            console.log(JSON.stringify(data))


            response = await axios.post(`${store.getters['auth/ip']}/api/buyer/delivery/add`, JSON.stringify(data),{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`, // Включаем токен в заголовок
                      }
            });
            console.log(response)

            if (response.ok) {
                
                // const data = await response.json();
                // const buyer = data.data.user;
                console.log('__200')

                // return buyer;
            }
        } catch(error) {
            console.error('Произошла ошибка: ', error.message);
        }
    },
    async updateBuyerData(data){
        try {
            var response = null;
            const accessToken = localStorage.getItem('accessToken');
            console.log(JSON.stringify(data))


            response = await axios.put(`${store.getters['auth/ip']}/api/auth/buyer/profile/change`, data ,{
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`, // Включаем токен в заголовок
                      }
            });
            console.log(response)

            if (response.ok) {
                
                // const data = await response.json();
                // const buyer = data.data.user;
                console.log('__200')

                // return buyer;
            }
        } catch(error) {
            console.error('Произошла ошибка: ', error.message);
        }
    },
    
    async updateSellerData(data){
        try {
            var response = null;
            const accessToken = localStorage.getItem('accessToken');
            console.log(JSON.stringify(data))


            response = await axios.put(`${store.getters['auth/ip']}/api/auth/seller/profile/change`, data ,{
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`, // Включаем токен в заголовок
                      }
            });
            console.log(response)

            if (response.ok) {
                
                // const data = await response.json();
                // const buyer = data.data.user;
                console.log('__200')

                // return buyer;
            }
        } catch(error) {
            console.error('Произошла ошибка: ', error.message);
        }
    },
    async updateBuyerAdressData(id, data){
        try {
            var response = null;
            const accessToken = localStorage.getItem('accessToken');
            console.log(JSON.stringify(data))


            response = await axios.put(`${store.getters['auth/ip']}/api/buyer/delivery/${id}/change`, JSON.stringify(data),{
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`, // Включаем токен в заголовок
                      }
            });
            console.log(response)

            if (response.ok) {
                
                // const data = await response.json();
                // const buyer = data.data.user;
                console.log('__200')

                // return buyer;
            }
        } catch(error) {
            console.error('Произошла ошибка: ', error.message);
        }
    },
    async deleteBuyerAdressData(id, data){
        try {
            var response = null;
            const accessToken = localStorage.getItem('accessToken');
            console.log(JSON.stringify(data))


            response = await axios.delete(`${store.getters['auth/ip']}/api/buyer/delivery/${id}/delete`,
            {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`, // Включаем токен в заголовок
                      }
            });
            console.log(response)

            if (response.ok) {
                
                // const data = await response.json();
                // const buyer = data.data.user;
                console.log('__200')

                // return buyer;
            }
        } catch(error) {
            console.error('Произошла ошибка: ', error.message);
        }
    },
    async updateBuyerDefaultAdressData(id, data){
        try {
            var response = null;
            const accessToken = localStorage.getItem('accessToken');
            console.log(JSON.stringify(data))


            response = await axios.put(`${store.getters['auth/ip']}/api/buyer/delivery/${id}/default`, JSON.stringify(data),{
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`, // Включаем токен в заголовок
                      }
            });
            console.log(response)

            if (response.ok) {
                
                // const data = await response.json();
                // const buyer = data.data.user;
                console.log('__200')

                // return buyer;
            }
        } catch(error) {
            console.error('Произошла ошибка: ', error.message);
        }
    },

    
};