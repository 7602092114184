// Файл: cart.js

// Функция для сохранения данных в корзине
export function saveToCart(basketItem) {
    // Получаем текущие данные корзины из localStorage (если они уже есть)
    const cartData = JSON.parse(localStorage.getItem('cart')) || [];
  
    // Добавляем выбранный элемент в данные корзины
    cartData.push(basketItem);
  
    // Сохраняем обновленные данные корзины в localStorage
    localStorage.setItem('cart', JSON.stringify(cartData));
  }
  
  // Функция для получения данных из корзины
  export function getCartData() {
    // Получаем текущие данные корзины из localStorage
    return JSON.parse(localStorage.getItem('cart')) || [];
  }

  export function updateCartItem(updatedBasketItem) {
    // Получаем текущие данные корзины из localStorage
    const cartData = JSON.parse(localStorage.getItem('cart')) || [];

    // Находим индекс элемента в данных корзины с помощью id
    const itemIndex = cartData.findIndex((cartItem) => cartItem.id === updatedBasketItem.id);

    // Если элемент найден (индекс не равен -1), обновляем его в данных корзины
    if (itemIndex !== -1) {
      cartData[itemIndex].count = updatedBasketItem.count;

      // Сохраняем обновленные данные корзины в localStorage
      localStorage.setItem('cart', JSON.stringify(cartData));
    }
  }

  
  // Функция для удаления данных из корзины
  export function removeFromCart(basketItem) {
    // Получаем текущие данные корзины из localStorage
    const cartData = JSON.parse(localStorage.getItem('cart')) || [];
  
    // Удаляем выбранный элемент из данных корзины, если он есть
    const updatedCartData = cartData.filter((cartItem) => cartItem.id !== basketItem.id);
  
    // Сохраняем обновленные данные корзины в localStorage
    localStorage.setItem('cart', JSON.stringify(updatedCartData));
  }
  
  // Функция для очистки данных корзины
  export function clearCart() {
    // Очищаем данные корзины в localStorage
    localStorage.removeItem('cart');
  }
  
  