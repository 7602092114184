import store from '@/store/index.js';
import router from '@/router';
import axios from '@/axiosConfig';

class AuthService {

    async login(loginRequest) {
        try {
                var response = await axios.post( `/api/auth/signin`, JSON.stringify(loginRequest), 
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

            // //const apiUrl = this.$store.getters['auth/ip'];
            // var response = null;

            // console.log(isBuyer);

            // if (isBuyer === 0) { //Это ошибка в Login. Надо переименовать или заменить значение
            //     response = await axios.post( `/api/auth/buyer/signin`, JSON.stringify(loginRequest), 
            //     {
            //         headers: {
            //             'Content-Type': 'application/json',
            //         }
            //     });
            // } else {
            //     response = await axios.post( `/api/auth/seller/signin`, JSON.stringify(loginRequest), 
            //     {
            //         headers: {
            //             'Content-Type': 'application/json',
            //         }
            //     });
            // }

            if (response.status === 200) {
                const data = response.data.data;
                
                console.log(data.user.refreshToken)
                const accessToken =  data.user.accessToken;
                const refreshToken =  data.user.refreshToken;
                const userName = data.user.name;
                const userType = data.user.userType;

                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                localStorage.setItem('userName', userName);
                localStorage.setItem('userType', userType);

                store.dispatch('auth/changeAuth', true);
                store.dispatch('auth/changeUserName', userName);
                store.dispatch('auth/changeUserType', userType);

                return 1

               // router.push('/');
            } else {
                console.log("+++++++token")

                console.error('Error submitting signup request:', response.statusText);
                return 0
            }

        } catch (error) {
            console.error('Произошла ошибка: ', error.message);
        }
    }

    logout() {
        store.dispatch('auth/changeAuth', false);
        store.dispatch('auth/changeUserName', null);
        store.dispatch('auth/changeUserType', null);

        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('userType');

        router.push('/');
    }

    async isAuthentication() {
        try {
            let token1 = localStorage.getItem("accessToken")//.slice(7)
           let  refreshToken = localStorage.getItem("refreshToken")//.slice(7)
            const response = await axios.post(`/api/auth/token/check`, {
  
            //const response = await axios.post(`http://192.168.1.101:8080/api/auth/token/check`, {
              accessToken: 'Bearer ' + token1,
              refreshToken: 'Bearer ' + refreshToken
            });
            var token = response.data.data.token;
     
            console.log("+++++++token")
     
            if(token === true){
                console.log("+++++++token")
              store.dispatch('auth/changeAuth', true);
              store.dispatch('auth/changeUserName', localStorage.getItem("userName"));
               store.dispatch('auth/changeUserType', localStorage.getItem("userType"));              
            }
             else if(token.accessToken){
                store.dispatch('auth/changeAuth', true);
              store.dispatch('auth/changeUserName', localStorage.getItem("userName"));
               store.dispatch('auth/changeUserType', localStorage.getItem("userType"));
            localStorage.setItem('token', JSON.stringify(token.accessToken));
              localStorage.setItem('refreshToken',  JSON.stringify(token.refreshToken));
  
             }
             else {
             store.dispatch('auth/changeAuth', false);
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken')
              localStorage.removeItem('userName');
              localStorage.removeItem('userType');
            }
  
            }
            catch (error) {
            console.log(error);
            store.dispatch('auth/changeAuth', false);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('userName');
            localStorage.removeItem('userType');
            //this.errorMessage = 'Invalid login or password';
            //localStorage.removeItem('token');
            //localStorage.removeItem('refreshToken')
           // store.dispatch('auth/changeAuth', false);
          } 
          
        }
        
      //   else{
      //     this.$store.commit('setAuthenticated', false);
      //   }
      

    async getUserByPrincipal() {
        try {
            var response = null;
            const accessToken = localStorage.getItem('accessToken');

            //response = await fetch(`${store.getters['auth/ip']}/api/order/all/my`, {
            response = await axios.get(`/api/user/profile`, {

                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`, // Включаем токен в заголовок
                      }
            });

            console.log(response.status)
            if (response.status === 200) {
                const data = response.data;
                const buyer = data.data.user;

                console.log(data.data)

                return buyer;
            }
        } catch(error) {
            console.error('Произошла ошибка: ', error.message);
        }
    }

  async  changePassword(data, usertype){
        try {
            var response = null;
            const accessToken = localStorage.getItem('accessToken');
console.log(data)
            //response = await fetch(`${store.getters['auth/ip']}/api/order/all/my`, {
              //  console.log(data)
            response = await axios.put(`${store.getters['auth/ip']}/api/auth/${usertype.toLowerCase()}/password/change`,
            
            data, {

                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`, // Включаем токен в заголовок
                      }
            });
            console.log("____________principal___")
            if (response.status === 200) {
                return 1;
            }
        } catch(error) {
            console.error('Произошла ошибка: ', error.message);
        }
    }

}

export default new AuthService();