import { createRouter, createWebHashHistory } from 'vue-router';
import AuthService from '@/service/AuthService';

 
 const routes = [
       {
     path: '/:lang?/',  // Добавлен параметр для языка
     component: () => import('./components/Home.vue'),
     name: 'home',
     
   },
   {
     path: '/:lang?/checkout',
     component: () => import('./components/Checkout.vue'),
     name: 'Checkout',
   },
   {
     path: '/:lang?/tirelist',
     component: () => import('./components/TireList.vue'),
     name: 'TireList',
   },
   {
    path: '/:lang?/:car?/findincatalog',
    component: () => import('./components/Catalog/CatalogNumChoice.vue'),
    name: 'CatalogNumChoice',
  },
  //  {
  //   path: '/:lang?/parts',
  //   component: () => import('./components/OtherAP.vue'),
  //   name: 'OtherAP',
  // },

  
  // {
  //   path: '/:lang?/:car?/findincatalog/tree/:model_id/:pc_id',
  //   component: () => import('./components/Catalog/ModelTree.vue'),
  //   name: 'ModelTree',
  //   props: true,
  // },
  {
    path: '/:lang?/:car?/findincatalog/tree/:model_id?',
    component: () => import('./components/Catalog/EngineType.vue'),
    name: 'EngineType',
    props: true,
  },
  {
    path: '/:lang?/:car?/findincatalog/tree/:model_id?/:pc_id?/:strId?',
    component: () => import('./components/OtherAP.vue'),
    name: 'OtherAP',
    props: true,
  },
  {
    path: '/:lang?/searchparts/:number?',
    component: () => import('./components/OtherAP.vue'),
    name: 'SearchParts',
  },
  {
    path: '/:lang?/autobrands',
    component: () => import('./components/Catalog/AutoBrands.vue'),
    name: 'AutoBrands',
    props: true,
  },
   {
     path: '/:lang?/company/dashboard',
     component: () => import('./components/Dashboard.vue'),
     name: 'Dashboard',
   },
   {
    path: '/:lang?/company/myparts',
    component: () => import('./components/MyParts/MyParts.vue'),
    name: 'MyParts',
  },
   {
     path: '/:lang?/company/orders',
     component: () => import('./components/CompanyListOrders.vue'),
     name: 'CompanyListOrders',
     beforeEnter: async (to, from, next) => {
          
          if (localStorage.getItem('accessToken')) {
            next();
          } else {
            next("/login");
          }
        },
          
   },
   {
   // images
//      path: '/:lang?/images',
//      component: () => import('./components/CompanyListOrders.vue'),
//      name: 'CompanyListOrders',
//      beforeEnter: async (to, from, next) => {
          
//           if (localStorage.getItem('accessToken')) {
//             next();
//           } else {
//             next("/login");
//           }
//         },
//    },
   //{
     path: '/:lang?/company/profile',
     component: () => import('./components/ProfileCompany.vue'),
     name: 'ProfileCompany',
     props: true,
     beforeEnter: async (to, from, next) => {
        //if (AuthService.isAuthentication()) {
          const user = await AuthService.getUserByPrincipal();
  
          if (user) {
            to.params.userZ = user;
            next();
          }
         else {
          next("/login");
        }
      },
   },
   {
     path: '/:lang?/company/reviews',
     component: () => import('./components/CompanyListReviews.vue'),
     name: 'CompanyListReviews',
   },
   {
     path: '/:lang?/login',
     component: () => import('./components/Login.vue'),
     name: 'Login',
   },
   {
     path: '/:lang?/oil',
     component: () => import('./components/Oil.vue'),
     name: 'Oil',
   },
   {
    path: '/:lang?/catalog',
    component: () => import('./components/PartsLists/PartsSearch.vue'),
    name: 'PartsSearch',
  },
  {
    path: '/:lang?/vwsearch',
    component: () => import('./components/PartsLists/VW/VWModels.vue'),
    name: 'VWModels',
  },
  {
    path: '/:lang?/vendors',
    component: () => import('./components/PartsLists/Vendors.vue'),
    name: 'Vendors',
  },
  {
    path: '/:lang?/vwsearch/:auto',
    component: () => import('./components/PartsLists/VW/VWGolf.vue'),
    name: 'VWGolf',
  },
  {
    path: '/:lang?/vwsearch/:auto/types/:year_id',
    component: () => import('./components/PartsLists/VW/VWTypes.vue'),
    name: 'VWTypes',
    props: true,
  },
  {
    path: '/:lang?/vwsearch/:auto/types/:year_id/:group_id',
    component: () => import('./components/PartsLists/VW/VWPartsGroups.vue'),
    name: 'VWPartsGroups',
    props: true,
  },
  {
    path: '/:lang?/vwsearch/:auto/types/:year_id/:group_id/:part_id',
    component: () => import('./components/PartsLists/VW/VWPartsList.vue'),
    name: 'VWPartsList',
    props: true,
  },
  // {
  //   path: '/:lang?/oil12',
  //   component: () => import('./components/PartsLists/PartsSearch.vue'),
  //   name: 'PartsSearchFebestTable',
  // },
  {
    path: '/:lang?/oilsearch',
    component: () => import('./components/PartsLists/OilSearch.vue'),
    name: 'OilSearch',
  },
  {
    path: '/:lang?/tiresSearch',
    component: () => import('./components/PartsLists/TiresSearch.vue'),
    name: 'TiresSearch',
  },
   {
     path: '/:lang?/oil-item/:partnumber?',
     component: () => import('./components/OilItem.vue'),
     name: 'OilItem',
     props: true,
   },
   {
    path: '/:lang?/part-item/',
    component: () => import('./components/OtherAPItem.vue'),
    name: 'ShowParts',
    props: true,
  },
   
   {
     path: '/:lang?/order',
     component: () => import('./components/Order.vue'),
     name: 'Order',
   },
   {
     path: '/:lang?/order/summary/:order_id',
     component: () => import('./components/Summary.vue'),
     name: 'Summary',
   },
   {
     path: '/:lang?/signup',
     component: () => import('./components/Registration.vue'),
     name: 'Registration',
   },
   {
     path: '/:lang?/tire',
     component: () => import('./components/Tire.vue'),
     name: 'Tire',
   },
   {
     path: '/:lang?/tire-item/:partnumber',
     component: () => import('./components/TireItem.vue'),
     name: 'TireItem',
     props: true,
   },
   {
     path: '/:lang?/tirelist/:partnumber',
     component: () => import('./components/TireListItem.vue'),
     name: 'tirelistitem',
     props: true,
   },
   {
     path: '/:lang?/aboutus',
     component: () => import('./components/AboutUs.vue'),
     name: 'aboutus',
     props: true,
   },
   {
    path: '/:lang?/delivery',
    component: () => import('./components/Delivery.vue'),
    name: 'delivery',
    props: true,
  },
  {
    path: '/:lang?/blog',
    component: () => import('./components/Blog/BlogCommon.vue'),
    name: 'Blog',
    props: true,
  },
  {
    path: '/:lang?/filter-kabine-vw-golf56/:id',
    component: () => import('./components/Blog/FilterKabineVWGolf5.vue'),
    name: 'FilterKabineVWGolf5',
    props: true,
  },
  {
    path: '/:lang?/delivery',
    component: () => import('./components/Delivery.vue'),
    name: 'delivery',
    props: true,
  },
   {
     path: '/:lang?/partners',
     component: () => import('./components/Partners.vue'),
     name: 'partners',
     props: true,
     
   },
   {
     path: '/:lang?/addad/',
     component: () => import('./components/AddPart/CommonAdd.vue'),
     name: 'CommonAdd',
     props: true,
     beforeEnter: async (to, from, next) => {
        //if (AuthService.isAuthentication()) {
            if (localStorage.getItem('accessToken')) {
                next();
              } else {
                next("/login");
              }
      },
   },
   {
     path: '/:lang?/user/change/password',
     component: () => import('./components/ChangePassword.vue'),
     name: 'ChangePassword',
   },
   {
     path: '/:lang?/user/profile',
     component: () => import('./components/ProfileUser.vue'),
     name: 'ProfileUser',
     props: true,
     beforeEnter: async (to, from, next) => {
       //if (AuthService.isAuthentication()) {
         const user = await AuthService.getUserByPrincipal();
 
         if (user) {
           to.params.userZ = user;
           next();
         }
        else {
         next("/login");
       }
     },
   } , 
   {
     path: '/:lang?/user/order/history',
     component: () => import('./components/OrderHistoryUser.vue'),
     name: 'OrderHistoryUser',
     beforeEnter: async (to, from, next) => {
        //if (AuthService.isAuthentication()) {
          if (localStorage.getItem('accessToken')) {
            next();
          } else {
            next("/login");
          }
      },
   },
   {
     path: '/:lang?/:any(.*)',
     component: () => import('./components/404.vue'),
     name: '404',
   },
   {
     path: '/:lang?/403',
     component: () => import('./components/403.vue'),
     name: '403',
   },
 ];
 
 const router = createRouter({
     history: createWebHashHistory(),
     routes: routes,
     scrollBehavior (to, from, savedPosition) {
         if (savedPosition)
             return savedPosition
         else
             return { left: 0, top: 0 };
     }
 });

 
  export default router;







//  import {createRouter, createWebHashHistory} from 'vue-router';
//  import AuthService from '@/service/AuthService';


// const routes = [
//   {
//     path: '/:lang',  // Добавлен параметр для языка
//     component: () => import('./components/Home.vue'),
//     children: [
   
//       {
//         path: 'checkout',
//         component: () => import('./components/Checkout.vue'),
//         name: 'Checkout',
//       },
//       {
//         path: 'tirelist',
//         component: () => import('./components/TireList.vue'),
//         name: 'TireList',
//       },
//       {
//         path: 'company/dashboard',
//         component: () => import('./components/Dashboard.vue'),
//         name: 'Dashboard',
//       },
//       {
//         path: 'company/orders',
//         component: () => import('./components/CompanyListOrders.vue'),
//         name: 'CompanyListOrders',
//       },
//       {
//         path: 'company/profile',
//         component: () => import('./components/ProfileCompany.vue'),
//         name: 'ProfileCompany',
//       },
//       {
//         path: 'company/reviews',
//         component: () => import('./components/CompanyListReviews.vue'),
//         name: 'CompanyListReviews',
//       },
//       {
//         path: 'login',
//         component: () => import('./components/Login.vue'),
//         name: 'Login',
//       },
//       {
//         path: 'oil',
//         component: () => import('./components/Oil.vue'),
//         name: 'Oil',
//       },
//       {
//         path: 'oil-item/:partnumber',
//         component: () => import('./components/OilItem.vue'),
//         name: 'OilItem',
//         props: true,
//       },
//       {
//         path: 'order',
//         component: () => import('./components/Order.vue'),
//         name: 'Order',
//       },
//       {
//         path: 'order/summary',
//         component: () => import('./components/Summary.vue'),
//         name: 'Summary',
//       },
//       {
//         path: 'signup',
//         component: () => import('./components/Registration.vue'),
//         name: 'Registration',
//       },
//       {
//         path: 'tire',
//         component: () => import('./components/Tire.vue'),
//         name: 'Tire',
//       },
//       {
//         path: 'tire-item/:partnumber',
//         component: () => import('./components/TireItem.vue'),
//         name: 'TireItem',
//         props: true,
//       },
//       {
//         path: 'tirelist/:partnumber',
//         component: () => import('./components/TireListItem.vue'),
//         name: 'tirelistitem',
//         props: true,
//       },
//       {
//         path: 'aboutus',
//         component: () => import('./components/AboutUs.vue'),
//         name: 'aboutus',
//         props: true,
//       },
//       {
//         path: 'partners',
//         component: () => import('./components/Partners.vue'),
//         name: 'partners',
//         props: true,
//       },
//       {
//         path: 'addad',
//         component: () => import('./components/AddPart/CommonAdd.vue'),
//         name: 'CommonAdd',
//         props: true,
//       },
//       {
//         path: 'user/change/password',
//         component: () => import('./components/ChangePassword.vue'),
//         name: 'ChangePassword',
//       },
//       {
//         path: 'user/profile',
//         component: () => import('./components/ProfileUser.vue'),
//         name: 'ProfileUser',
//         props: true,
//         beforeEnter: async (to, from, next) => {
//           if (AuthService.isAuthentication()) {
//             const user = await AuthService.getUserByPrincipal();

//             if (user) {
//               to.params.userZ = user;
//               next();
//             }
//           } else {
//             next("/login");
//           }
//         },
//       },
//       {
//         path: 'user/order/history',
//         component: () => import('./components/OrderHistoryUser.vue'),
//         name: 'OrderHistoryUser',
//       },
//       {
//         path: ':any(.*)',
//         component: () => import('./components/404.vue'),
//         name: '404',
//       },
//       {
//         path: '403',
//         component: () => import('./components/403.vue'),
//         name: '403',
//       },
//     ],
//   },
// ];

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) return savedPosition;
//     else return { left: 0, top: 0 };
//   },
// });

// export default router;





//Прод который рабоатет и кооторый был
//  import {createRouter, createWebHashHistory} from 'vue-router';
//  import AuthService from '@/service/AuthService';
 
//  const routes = [
//        {
//      path: '/:lang?',  // Добавлен параметр для языка
//      component: () => import('./components/Home.vue'),
//      name: 'home',
//    },
//    {
//      path: '/:lang/checkout',
//      component: () => import('./components/Checkout.vue'),
//      name: 'Checkout',
//    },
//    {
//      path: '/:lang/tirelist',
//      component: () => import('./components/TireList.vue'),
//      name: 'TireList',
//    },
//    {
//      path: '/:lang/company/dashboard',
//      component: () => import('./components/Dashboard.vue'),
//      name: 'Dashboard',
//    },
//    {
//      path: '/:lang/company/orders',
//      component: () => import('./components/CompanyListOrders.vue'),
//      name: 'CompanyListOrders',
//    },
//    {
//      path: '/:lang/company/profile',
//      component: () => import('./components/ProfileCompany.vue'),
//      name: 'ProfileCompany',
//    },
//    {
//      path: '/:lang/company/reviews',
//      component: () => import('./components/CompanyListReviews.vue'),
//      name: 'CompanyListReviews',
//    },
//    {
//      path: '/:lang/login',
//      component: () => import('./components/Login.vue'),
//      name: 'Login',
//    },
//    {
//      path: '/:lang/oil',
//      component: () => import('./components/Oil.vue'),
//      name: 'Oil',
//    },
//    {
//      path: '/:lang/oil-item/:partnumber',
//      component: () => import('./components/OilItem.vue'),
//      name: 'OilItem',
//      props: true,
//    },
//    {
//      path: '/:lang/order',
//      component: () => import('./components/Order.vue'),
//      name: 'Order',
//    },
//    {
//      path: '/:lang/order/summary',
//      component: () => import('./components/Summary.vue'),
//      name: 'Summary',
//    },
//    {
//      path: '/:lang/signup',
//      component: () => import('./components/Registration.vue'),
//      name: 'Registration',
//    },
//    {
//      path: '/:lang/tire',
//      component: () => import('./components/Tire.vue'),
//      name: 'Tire',
//    },
//    {
//      path: '/:lang/tire-item/:partnumber',
//      component: () => import('./components/TireItem.vue'),
//      name: 'TireItem',
//      props: true,
//    },
//    {
//      path: '/:lang/tirelist/:partnumber',
//      component: () => import('./components/TireListItem.vue'),
//      name: 'tirelistitem',
//      props: true,
//    },
//    {
//      path: '/:lang/aboutus',
//      component: () => import('./components/AboutUs.vue'),
//      name: 'aboutus',
//      props: true,
//    },
//    {
//      path: '/:lang/partners',
//      component: () => import('./components/Partners.vue'),
//      name: 'partners',
//      props: true,
//    },
//    {
//      path: '/:lang/addad/',
//      component: () => import('./components/AddPart/CommonAdd.vue'),
//      name: 'CommonAdd',
//      props: true,
//    },
//    {
//      path: '/:lang/user/change/password',
//      component: () => import('./components/ChangePassword.vue'),
//      name: 'ChangePassword',
//    },
//    {
//      path: '/:lang/user/profile',
//      component: () => import('./components/ProfileUser.vue'),
//      name: 'ProfileUser',
//      props: true,
//      beforeEnter: async (to, from, next) => {
//        if (AuthService.isAuthentication()) {
//          const user = await AuthService.getUserByPrincipal();
 
//          if (user) {
//            to.params.userZ = user;
//            next();
//          }
//        } else {
//          next("/login");
//        }
//      },
//    },
//    {
//      path: '/:lang/user/order/history',
//      component: () => import('./components/OrderHistoryUser.vue'),
//      name: 'OrderHistoryUser',
//    },
//    {
//      path: '/:lang/:any(.*)',
//      component: () => import('./components/404.vue'),
//      name: '404',
//    },
//    {
//      path: '/:lang/403',
//      component: () => import('./components/403.vue'),
//      name: '403',
//    },
//  ];
 
//  const router = createRouter({
//      history: createWebHashHistory(),
//      routes: routes,
//      scrollBehavior (to, from, savedPosition) {
//          if (savedPosition)
//              return savedPosition
//          else
//              return { left: 0, top: 0 };
//      }
//  });
 
//   export default router;
 
 





















// import { createRouter, createWebHashHistory } from 'vue-router';
// import AuthService from '@/service/AuthService';

// const routes = [
//   {
//     path: '/:lang?',  // Добавлен параметр для языка
//     component: () => import('./components/Home.vue'),
//     name: 'home',
//   },
//   {
//     path: '/:lang/checkout',
//     component: () => import('./components/Checkout.vue'),
//     name: 'Checkout',
//   },
//   {
//     path: '/:lang/tirelist',
//     component: () => import('./components/TireList.vue'),
//     name: 'TireList',
//   },
//   {
//     path: '/:lang/company/dashboard',
//     component: () => import('./components/Dashboard.vue'),
//     name: 'Dashboard',
//   },
//   {
//     path: '/:lang/company/orders',
//     component: () => import('./components/CompanyListOrders.vue'),
//     name: 'CompanyListOrders',
//   },
//   {
//     path: '/:lang/company/profile',
//     component: () => import('./components/ProfileCompany.vue'),
//     name: 'ProfileCompany',
//   },
//   {
//     path: '/:lang/company/reviews',
//     component: () => import('./components/CompanyListReviews.vue'),
//     name: 'CompanyListReviews',
//   },
//   {
//     path: '/:lang/login',
//     component: () => import('./components/Login.vue'),
//     name: 'Login',
//   },
//   {
//     path: '/:lang/oil',
//     component: () => import('./components/Oil.vue'),
//     name: 'Oil',
//   },
//   {
//     path: '/:lang/oil-item/:partnumber',
//     component: () => import('./components/OilItem.vue'),
//     name: 'OilItem',
//     props: true,
//   },
//   {
//     path: '/:lang/order',
//     component: () => import('./components/Order.vue'),
//     name: 'Order',
//   },
//   {
//     path: '/:lang/order/summary',
//     component: () => import('./components/Summary.vue'),
//     name: 'Summary',
//   },
//   {
//     path: '/:lang/signup',
//     component: () => import('./components/Registration.vue'),
//     name: 'Registration',
//   },
//   {
//     path: '/:lang/tire',
//     component: () => import('./components/Tire.vue'),
//     name: 'Tire',
//   },
//   {
//     path: '/:lang/tire-item/:partnumber',
//     component: () => import('./components/TireItem.vue'),
//     name: 'TireItem',
//     props: true,
//   },
//   {
//     path: '/:lang/tirelist/:partnumber',
//     component: () => import('./components/TireListItem.vue'),
//     name: 'tirelistitem',
//     props: true,
//   },
//   {
//     path: '/:lang/aboutus',
//     component: () => import('./components/AboutUs.vue'),
//     name: 'aboutus',
//     props: true,
//   },
//   {
//     path: '/:lang/partners',
//     component: () => import('./components/Partners.vue'),
//     name: 'partners',
//     props: true,
//   },
//   {
//     path: '/:lang/addad/',
//     component: () => import('./components/AddPart/CommonAdd.vue'),
//     name: 'CommonAdd',
//     props: true,
//   },
//   {
//     path: '/:lang/user/change/password',
//     component: () => import('./components/ChangePassword.vue'),
//     name: 'ChangePassword',
//   },
//   {
//     path: '/:lang/user/profile',
//     component: () => import('./components/ProfileUser.vue'),
//     name: 'ProfileUser',
//     props: true,
//     beforeEnter: async (to, from, next) => {
//       if (AuthService.isAuthentication()) {
//         const user = await AuthService.getUserByPrincipal();

//         if (user) {
//           to.params.userZ = user;
//           next();
//         }
//       } else {
//         next("/login");
//       }
//     },
//   },
//   {
//     path: '/:lang/user/order/history',
//     component: () => import('./components/OrderHistoryUser.vue'),
//     name: 'OrderHistoryUser',
//   },
//   {
//     path: '/:lang/:any(.*)',
//     component: () => import('./components/404.vue'),
//     name: '404',
//   },
//   {
//     path: '/:lang/403',
//     component: () => import('./components/403.vue'),
//     name: '403',
//   },
// ];

// const router = createRouter({
//     history: createWebHashHistory(),
//     routes,
//     scrollBehavior(to, from, savedPosition) {
//       if (savedPosition) {
//         return savedPosition;
//       } else {
//         return { left: 0, top: 0 };
//       }
//     },
//   });
  
//   router.beforeEach(async (to, from, next) => {
//     const lang = to.params.lang || localStorage.getItem('locale') || 'en';
    
//     // Проверяем, что i18n установлен в корневом компоненте Vue
//     if (router.currentRoute.value.meta.i18n) {
//       router.currentRoute.value.meta.i18n.locale = lang;
//     }
    
//     if (!to.path.startsWith(`/${lang}`)) {
//       next({ path: `/${lang}${to.path}`, params: { lang } });
//     } else {
//       next();
//     }
//   });
//   export default router;