// i18n.js

// i18n.js

import { createI18n } from 'vue-i18n';

import ru from './locales/ru.json';
import me from './locales/me.json';
import en from './locales/en.json';
const messages = {
  en,
  ru,
  me
};

const i18n = createI18n({
  legacy: false,
  locale: 'me',
  fallbackLocale: 'me',
  messages,
});

export default i18n;


// import { createI18n } from 'vue-i18n';

// const i18n = createI18n({
//   legacy: false,
//   locale: 'en',
//   fallbackLocale: 'en',
//   messages: {
//     en: {
//       hello: 'Hello!',
//     },
//     es: {
//       hello: '¡Hola!',
//     },
//     // Добавьте переводы для других языков...
//   },
// });

// export default i18n;
