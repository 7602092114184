
const authModule = {
    state: () => ({
        isAuth: false,
        userName: null,
        userType: null,
        ip:'',
        //ip:'https://localhost:8080',
        //ip:'http://192.168.1.102:8080',
       // ip:'http://172.20.10.5:8080',

        curency:'EUR'
    }),

    mutations: {
        setAuth(state, isAuth) {
            state.isAuth = isAuth;
        },

        setUserName(state, userName) {
            state.userName = userName;
        },

        setUserType(state, userType) {
            state.userType = userType;
        }
    },

    actions: {
        changeAuth({commit}, isAuth) {
            commit('setAuth', isAuth);
        },

        changeUserName({commit}, userName) {
            commit('setUserName', userName);
        },

        changeUserType({commit}, userType) {
            commit('setUserType', userType);
        },
    },
    
    getters: {
        ip: state => {
            return state.ip
        },
        curency: state => {
            return state.curency
        },


    },

    namespaced: true
}

export default authModule;